<template>
  <div class="has-text-centered">
    <div class="api-subscription-title has-text-grey-dark">Your Current Plan</div>
    <div v-if="!tabProperties.isUnlimited">
      <div class="api-subscription-detail tag is-danger has-text-weight-bold is-large">{{tabProperties.clientPlan}}</div>
      <div class="api-subscription-sub-detail api-credits">({{tabProperties.clientPlanDetail.description}})</div>
      <div class="api-subscription-sub-detail api-credits">*1 Credit = 1 Execute API Call</div>
    </div>
    <div v-else class="api-subscription-detail tag is-danger has-text-weight-bold is-large">Unlimited</div>

    <div v-show="tabProperties.clientFuturePlan" class="future-plan-section" v-cloak>
      <div class="api-subscription-title has-text-grey-dark">Your Plan From Next Billing Period</div>
      <div class="api-subscription-detail tag is-danger has-text-weight-bold is-large">{{tabProperties.clientFuturePlan}}</div>
      <div class="api-subscription-sub-detail col-xs-12 api-credits">({{tabProperties.clientFuturePlanDetail.description}})</div>
      <div class="api-subscription-sub-detail api-credits">*1 Credit = 1 Execute API Call</div>
    </div>
    <div v-show="tabProperties.isSubscriptionCancelled" class="reactivate-section" v-cloak>
      <div class="has-text-grey-dark has-text-weight-bold">Subscription cancelled effective from end of current billing period</div>
      <button class="button is-info" @click="showReactivateTab">Re-Activate</button>
    </div>
    <div class="has-text-centered cancel-subscription-link" v-if="!tabProperties.isSubscriptionCancelled">
      <a class="is-link has-text-danger" @click="showCancelTab" v-if="!tabProperties.isUnlimited">Cancel Subscription</a>
    </div>
  </div>
</template>

<script>
import { eventBus } from '../../assets/javascript/event-bus'

export default {
  name: 'apiCurrentPlan',
  props: ['tabProperties'],
  methods: {
    showCancelTab () {
      eventBus.$emit('APIChangeTab', { name: 'Cancel Subscription', component: () => import('./APICancelSubscription') })
    },
    showReactivateTab () {
      eventBus.$emit('APIChangeTab', { name: 'Re-activate Subscription', component: () => import('./APIReactivateSubscription') })
    }
  }
}
</script>

<style scoped lang="scss">
  .api-subscription-title {
    text-decoration: underline;
    font-weight: bold;
    font-size: 1em;
  }

  .api-subscription-detail {
    margin-top: 1em;
    margin-bottom: 1em;
  }

  .api-subscription-sub-detail {
    font-weight: bold;
    font-size: 0.8em;
  }

  .cancel-subscription-link {
    text-decoration: underline;
    margin-bottom: 0.5em;
    margin-top: 1em;
  }

  .cancel-subscription-link a {
    font-size: 0.9em;
    font-weight: bold;
  }

  .future-plan-section, .reactivate-section {
    margin-top: 20px;
  }
</style>
